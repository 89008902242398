import { EventEmitter, Injectable, Output, signal } from '@angular/core';
import {
  Observable,
  catchError,
  map,
  mergeMap,
  throwError,
} from 'rxjs';
import { TokenService } from '../auth/token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../settings.service';
import { UserMapper } from 'src/app/models/user/mappers/user-mapper';
import { UserDto } from 'src/app/models/user/user-dto';
import { User } from 'src/app/models/user/user';
import { UserRequest } from 'src/app/models/user/requests/user-request';
import { SupplierAccount } from 'src/app/models/user/supplier-account';
import { AcceptUserInvitationRequest, InviteUserRequest, UserInvitation } from 'src/app/models/user/requests/invite-user-request';
import { SupplierAccountUser } from 'src/app/models/user/supplier-account-user';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  @Output() onUserInvitationAccepted = new EventEmitter<string>();

  getUser = (): User => {
    const user: User = JSON.parse(localStorage.getItem('current_user'));
    return user;
  };

  public userSignal = signal<User | null>(null);
  public isAuthenticated = signal(false);
  public isLoading = signal(false);
  constructor(private tokenService: TokenService, private http: HttpClient) { }

  isLoggedIn = this.userSignal() !== null;
  logout = () => {
    this.tokenService.deleteToken();
    this.userSignal.set(null);
    this.isAuthenticated.set(false);
    localStorage.removeItem('selected-service-category');
    localStorage.removeItem('selected-supplier-account');
  };


  load = (): Observable<any> => {
    this.isLoading.set(true);

    let chain: Observable<any> = this.http.get<User>(
      SettingsService.settings.api.tenantServiceUri + '/users/me'
    );

    return chain.pipe(
      catchError((err) => {
        this.isLoading.set(false);
        return throwError(() => err);
      }),
      mergeMap((u) => {
        return this.http
          .get<Array<SupplierAccount>>(
            `${SettingsService.settings.api.fleetSupplierServiceUri}/supplier-account/by-user-id/${u.id}`
          )
          .pipe(
            mergeMap((sa) => {
              if (sa && sa.length > 0) {
                u.supplierAccounts = sa;

                const prevAccount: SupplierAccount = JSON.parse(localStorage.getItem('selected-supplier-account'));
                const selectedSupplierAccount = prevAccount ? prevAccount : sa[0];

                this.setSupplierAccount(selectedSupplierAccount, u);
              }

              return this.http.get<any>(
                `${SettingsService.settings.api.fleetSupplierServiceUri}/supplier-account-user/has-administrator-privileges/${u.id}?supplierAccountId=${sa[0].id}`
              ).pipe(
                map((response) => {
                  u.isAdmin = response.isAdministrator;
                  return u;
                })
              );
            }),
            map((user) => {
              this.isLoading.set(false);
              this.userSignal.set(user);
              this.isAuthenticated.set(true);
              return user;
            })
          );
      })
    );
  };


  getIdPSourceForUser(partnerName: string, userId: string): Observable<any> {
    const url = SettingsService.settings.api.tenantServiceUri + '/federatedAuthenticationProvider/user?partnerName=' + partnerName + '&userId=' + userId;
    return this.http.get<any>(url);
  }

  selectSupplierAccount(supplierAccountId: string) {
    const user = this.userSignal();
    if (user) {
      const selectedSupplier = user.supplierAccounts.find(sa => sa.id === supplierAccountId);
      if (selectedSupplier) {
        this.setSupplierAccount(selectedSupplier, user);
        this.userSignal.set(user);
      }
    }
  }

  setSupplierAccount(supplierAccount: SupplierAccount, user: User) {
    user.supplierAccount = Object.assign(new SupplierAccount(), supplierAccount);
    user.supplierAccountId = supplierAccount.id;
    user.supplierAccount.serviceCategories = supplierAccount.serviceCategories;

    const selectedServiceCategory = localStorage.getItem('selected-service-category');
    if (
      selectedServiceCategory &&
      user.supplierAccount.serviceCategories.some(s => s === selectedServiceCategory)
    ) {
      user.supplierAccount.serviceCategory = selectedServiceCategory;
    } else if (user.supplierAccount.serviceCategories.length === 1) {
      user.supplierAccount.serviceCategory = user.supplierAccount.serviceCategories[0];
      localStorage.setItem('selected-service-category', user.supplierAccount.serviceCategory);
    }

    localStorage.setItem('selected-supplier-account', JSON.stringify(supplierAccount));
  }

  getUsers = (): Observable<Array<User>> => {
    const supplierAccountId = this.userSignal().supplierAccountId;

    const config: any = {
      url: `${SettingsService.settings.api.fleetSupplierServiceUri}/customers?accountId=${supplierAccountId}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };

    return this.http.get<Array<User>>(config.url).pipe(
      map((response) => {
        return response.map((x) =>
          Object.assign(UserMapper.mapToDomain(x))
        );
      }),
    );
  }

  getUser2 = (userId: string): Observable<User> => {
    const config: any = {
      url: `${SettingsService.settings.api.fleetSupplierServiceUri}/customers`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };

    return this.http.get<UserDto>(config.url).pipe(
      map((response: UserDto) => {
        return UserMapper.mapToDomain(response)
      })
    )
  };

  addUser = (request: UserRequest): Observable<any> => {
    const supplierAccountId = this.userSignal().supplierAccountId;
    const url = `${SettingsService.settings.api.fleetSupplierServiceUri}/customers?accountId=${supplierAccountId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    return this.http.post(url, JSON.stringify(request), httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  };

  updateUser = (user: UserDto): Observable<any> => {
    const supplierAccountId = this.userSignal().supplierAccountId;
    const url = `${SettingsService.settings.api.fleetSupplierServiceUri}/supplier-account-users/${user.id}?supplierAccountId=${supplierAccountId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };
    return this.http.put(url, JSON.stringify(user), httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  };

  updateUserAdminAccess = (userId: string, isAdmin: boolean): Observable<any> => {
    const updatedByUserId = this.userSignal().id;
    const supplierAccountId = this.userSignal().supplierAccountId;

    const url = `${SettingsService.settings.api.fleetSupplierServiceUri}/supplier-account-user/${userId}?supplierAccountId=${supplierAccountId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    const request = {
      isAdmin: isAdmin,
      updatedByUserId: updatedByUserId
    }

    return this.http.put(url, JSON.stringify(request), httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  };

  deleteUser = (userId: string): Observable<any> => {
    const deletedByUserId = this.userSignal().id;
    const supplierAccountId = this.userSignal().supplierAccountId;

    const url = `${SettingsService.settings.api.fleetSupplierServiceUri}/supplier-account-user/${userId}?supplierAccountId=${supplierAccountId}&deletedByUserId=${deletedByUserId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    return this.http.delete(url, httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  };

  inviteUser = (request: InviteUserRequest): Observable<any> => {
    const supplierAccountId = this.userSignal().supplierAccountId;
    const url = `${SettingsService.settings.api.fleetSupplierServiceUri}/supplier-account-user/invite?supplierAccountId=${supplierAccountId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    return this.http.post(url, JSON.stringify(request), httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  };


  acceptInvitation = (request: AcceptUserInvitationRequest): Observable<any> => {
    const url = `${SettingsService.settings.api.fleetSupplierServiceUri}/supplier-account-user/accept-invitation`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    return this.http.post(url, JSON.stringify(request), httpOptions).pipe(
      map((res) => {
        this.onUserInvitationAccepted.emit(request.invitedUserEmail);
        return res;
      })
    );
  };

  getUserInvitation = (token: string): Observable<UserInvitation> => {
    const config: any = {
      url: `${SettingsService.settings.api.fleetSupplierServiceUri}/supplier-account-user/invitations/get-non-accepted-invitation/${token}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };

    return this.http.get<any>(config.url).pipe(
      map((response: any) => {
        return response;
      })
    )
  };

  getSupplierAccountUsers = (): Observable<Array<SupplierAccountUser>> => {
    const supplierAccountId = this.userSignal().supplierAccountId;

    const config: any = {
      url: `${SettingsService.settings.api.fleetSupplierServiceUri}/supplier-account-user?supplierAccountId=${supplierAccountId}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };

    return this.http.get<Array<SupplierAccountUser>>(config.url).pipe(
      map((response: any) => {
        return response;
      })
    )
  };

  getUsersByIds = (ids: string): Observable<Array<any>> => {
    const supplierAccountId = this.userSignal().supplierAccountId;

    const config: any = {
      url: `${SettingsService.settings.api.tenantServiceUri}/users/get-by-ids?ids=${ids}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };

    return this.http.get<Array<any>>(config.url).pipe(
      map((response: any) => {
        return response;
      })
    )
  };
}
